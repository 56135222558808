// svg icons utilities

$theme-and-colors: map-merge($colors, $theme-colors);
.icon {
  svg {
    vertical-align: baseline;
  }
  @each $color-name, $color in $theme-and-colors {
    &-#{$color-name} {
      fill: $color;
      stroke: $color;
    }
  }
}
