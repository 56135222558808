// -----------------------------------------------------------------------------
// Forms component's extension
// -----------------------------------------------------------------------------

// Indicate that a form field is required.
.required {
  font-size: 90%;
  color: $danger;
}

.create-form {
  margin: $spacer * 2 0;
}

.taglike-input {
  padding-left: 0;
}

.taglike-input input[type='checkbox'] {
  position: fixed;
  width: 0;
  opacity: 0;
}

.taglike-input label {
  display: inline-block;
  padding: calc($spacer / 4) $spacer;
  margin: calc($spacer / 4) 0;
  cursor: pointer;
  background-color: lighten($color: lightgrey, $amount: 10);
  border-radius: $spacer;
}

.taglike-input input[type='checkbox']:checked + label {
  background-color: $primary;
}

.taglike-input input[type='checkbox']:focus + label {
  border: calc($spacer / 8) grey;
}

.taglike-input label:hover {
  background-color: lighten($color: $primary, $amount: 15);
}

// Custom file input
.file-upload-wrapper {
  position: relative;
  width: 100%;
  height: $spacer * 3;

  @include media-breakpoint-up(md) {
    width: 75%;
  }

  @include media-breakpoint-up(lg) {
    width: 50%;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    display: block;
    width: calc(100% - 40px);
    height: calc($spacer * 3);
    font-weight: 300;
    line-height: calc($spacer * 3);
    color: $primary;
    pointer-events: none;
    content: attr(data-text);
    background: #fff;
    border-radius: 5px 10px 10px 5px;
  }

  &::before {
    position: absolute;
    top: 10%;
    right: 0;
    z-index: 25;
    display: inline-block;
    height: $spacer * 2;
    padding: 0 15px;
    font-size: $spacer;
    font-weight: 500;
    line-height: calc($spacer * 2);
    color: $black;
    pointer-events: none;
    content: 'Parcourir..';
    background: $primary;
    border-radius: $spacer;
  }

  &:hover {
    &::before {
      background: darken($primary, 10%);
    }
  }
  input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    display: block;
    width: 100%;
    height: calc($spacer * 3);
    padding: 0;
    margin: 0;
    cursor: pointer;
    opacity: 0;
  }
}

.methode {
  padding: $spacer $spacer 0 $spacer;
  margin: $spacer;
  border: 1px solid $primary;

  @include media-breakpoint-down(md) {
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
  }
}

.round-button {
  width: auto;
  padding-top: calc($spacer / 10);
  padding-bottom: calc($spacer / 10);
  color: $black;
  border-radius: $spacer;
}
