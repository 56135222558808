// -----------------------------------------------------------------------------
// Application-wide variables
// -----------------------------------------------------------------------------

// Path to fonts and images folders, relative to app.scss
$font-path: '../fonts';
$img-path: '../img';

//
// Bootstrap
//
// see: ../../node_modules/bootstrap/scss/_variables.scss

// Color system
// You could generate a color scheme with: https://palx.jxnblk.com

$blue: #0d6efd;
$indigo: #6610f2;
$purple: #b099ff;
$pink: #d63384;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #4dc19e;
$teal: #20c997;
$cyan: #0dcaf0;

$primary: $purple;
$secondary: $green;
$light: #f7f7f7;

// Options

$enable-validation-icons: false;

// fonts
$font-family-monospace: 'IBM Plex Mono';
$font-family-base: 'IBM Plex Sans';
$headings-font-family: $font-family-base;
$headings-font-weight: 400;
