.layout {
  display: flex;
  min-height: 100vh;
  &-menu {
    flex: 0 0 auto;
  }
  &-right {
    flex: 1 1 auto;
    overflow: auto;
  }
}

.logo {
  max-height: 80px;
}

footer {
  border-top: 2px solid grey;
}
