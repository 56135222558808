$angle-notch-size: 30px;

.fiche {
  position: relative;
  padding: 1px;
  background: $primary;
  &-inner {
    padding: 1rem;
    background: $white;
  }
}

.fiche,
.fiche-inner {
  clip-path: polygon(
    0% 0%,
    0% 100%,
    100% 100%,
    100% calc(0% + $angle-notch-size),
    calc(100% - $angle-notch-size) 0%
  );
  height: 100%;
}
