.menu {
  padding: 1rem;
  background: $primary;

  a {
    text-align: center;
    text-decoration: none;
  }

  @include media-breakpoint-up(sm) {
    &:hover {
      .text-on-collapse {
        display: inline;
        animation: slideFromRight 300ms ease 0s 1 normal forwards;
      }

      a {
        text-align: left;
      }
    }
  }

  .text-on-collapse {
    display: none;
    margin-left: $spacer;
    color: $white;
  }

  &.show {
    .text-on-collapse {
      display: inline;
    }
  }
}

@keyframes slideFromRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
