h1 {
  font-family: $font-family-monospace;
  color: $secondary;
}

h2 {
  color: $primary;
}

h3 {
  color: $secondary;
}
